/**
 * Expira API
 * The Expira RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface OTPDto { 
    type: OTPDto.TypeEnum;
    channel: OTPDto.ChannelEnum;
    recipient: string;
    firstName?: string;
}
export namespace OTPDto {
    export type TypeEnum = 'USER_REGISTRATION' | 'PHONE_NUMBER_UPDATE' | 'GENERAL' | 'ONBOARDING_PHONE' | 'ONBOARDING_EMAIL';
    export const TypeEnum = {
        USER_REGISTRATION: 'USER_REGISTRATION' as TypeEnum,
        PHONE_NUMBER_UPDATE: 'PHONE_NUMBER_UPDATE' as TypeEnum,
        GENERAL: 'GENERAL' as TypeEnum,
        ONBOARDING_PHONE: 'ONBOARDING_PHONE' as TypeEnum,
        ONBOARDING_EMAIL: 'ONBOARDING_EMAIL' as TypeEnum
    };
    export type ChannelEnum = 'SMS' | 'EMAIL' | 'PHONE_CALL';
    export const ChannelEnum = {
        SMS: 'SMS' as ChannelEnum,
        EMAIL: 'EMAIL' as ChannelEnum,
        PHONE_CALL: 'PHONE_CALL' as ChannelEnum
    };
}


