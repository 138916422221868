import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as d3 from 'd3';

@Component({
    selector: 'map-visualization',
    templateUrl: './map-visualization.component.html',
    styleUrls: ['./map-visualization.component.css']
})
export class MapVisualizationComponent implements OnInit {
    // @ViewChild('tooltip') tooltip!: NgbTooltip;
    @ViewChild('tooltip') tooltip: ElementRef;
    constructor() {
    }

    ngOnInit(): void {
        this.drawMap();
    }

    drawMap(): void {
        // The svg
        const svg = d3.select("#my_dataviz");
        const width = +svg.attr("width");
        const height = +svg.attr("height");

        // Map and projection
        const projection = d3.geoNaturalEarth1()
            .scale(width * 9.3 / Math.PI)
            .translate([width / 14, height / 0.9]);

        // Load external data and boot
        d3.json("assets/js/nigeria_geojson.geojson").then((data) => {
            // Draw the map
            svg.append("g")
                .selectAll("path")
                //@ts-ignore
                .data(data.features)
                .enter().append("path")
                .attr("fill", "#69b3a2")
                .attr("d", d3.geoPath()
                    .projection(projection)
                )
                .style("stroke", "#fff")
                .on('mouseover', (event, d) => {
                    // Change the fill color of the country
                    d3.select(event.currentTarget).attr("fill", "#2a9d8f");

                    //@ts-ignore
                    this.tooltip.nativeElement.innerHTML = `
                              <div #tooltip="ngbTooltip" ngbTooltip="" class="dash-card text-dark bg-white" style="max-width: 500px;">
                                <div style="font-size: 30px; font-weight: 600" class="my-2">
                                    <div style="font-size: 30px; font-weight: 600" class="my-2">Kaduna</div>
                                    <div>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                <tr style="color: rgba(0, 0, 0, 0.47);">
                                                    <th>Data</th>
                                                    <th>Percentage</th>
                                                    <th>Amount</th>
                                                </tr>
                                                </thead>
                                                <tbody style="font-weight: 500;">
                                                <tr>
                                                    <th>OKAY</th>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div>38%</div>
                                                            <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #65B765;">
                                                                <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                                                                     aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>375,183</td>
                                                </tr>
                                                <tr>
                                                    <th>BLUR</th>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div>38%</div>
                                                            <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #203E7A;">
                                                                <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                                                                     aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>375,183</td>
                                                </tr>
                                                <tr>
                                                    <th>TAMH</th>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div>38%</div>
                                                            <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #611F69;">
                                                                <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                                                                     aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>375,183</td>
                                                </tr>
                                                <tr>
                                                    <th>TAMM</th>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div>38%</div>
                                                            <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #FFA100;">
                                                                <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                                                                     aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>375,183</td>
                                                </tr>
                                                <tr>
                                                    <th>NULL</th>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div>38%</div>
                                                            <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #1D1C1D;">
                                                                <div class="progress-bar" role="progressbar" style="width: 25%" aria-valuenow="25"
                                                                     aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>375,183</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            `;
                    this.tooltip.nativeElement.style.opacity = 1;
                    this.tooltip.nativeElement.style.left = (event.pageX + 10) + 'px';
                    this.tooltip.nativeElement.style.top = (event.pageY - 20) + 'px';
                })
                .on('mouseout',(event, d) => {
                    // Restore the original fill color of the country
                    d3.select(event.currentTarget).attr("fill", "#69b3a2");
                    this.tooltip.nativeElement.style.opacity = 0;
                });

            // Load and plot the points

            d3.json("assets/js/polling_units.json").then((pointData) => {
                //@ts-ignore
                // pointData.forEach(v =>{
                //     if (Array.isArray(v.coordinates) && v.coordinates.length === 2) {
                //
                //     } else {
                //         console.log("The coordinates contain two array values." + v.name);
                //         // console.log("Latitude:", v.coordinates[0]);
                //         // console.log("Longitude:", v.coordinates[1]);
                //     }
                // });
                // console.log(pointData)
                const points = svg.append("g")
                    .selectAll("circle")
                    //@ts-ignore
                    .data(pointData)
                    .enter()
                    .append("circle")
                    //@ts-ignore
                    .attr('cx', (d) => projection([d.coordinates[1], d.coordinates[0]])[0])
                    //@ts-ignore
                    .attr('cy', (d) => projection([d.coordinates[1], d.coordinates[0]])[1])
                    .attr('r', 4)
                    //@ts-ignore
                    .attr('fill', (d) => d.state === 'Benue' ? 'red' : 'blue')
                    //@ts-ignore
                    .attr('stroke', '#fff')

                    .on("mouseover", function (event, d) {
                        //@ts-ignore
                        d3.select(this).attr("r", 8).text(d.name);
                    })
                    .on("mouseout", function (event, d) {
                        d3.select(this).attr("r", 5);
                    });
                // // Add labels to the plot points
                points.each((d) => {
                    //@ts-ignore
                        // //@ts-ignore
                        // .attr('x', projection([d.coordinates[1], d.coordinates[0]])[0] + 10)
                        // //@ts-ignore
                        // .attr('y', projection([d.coordinates[1], d.coordinates[0]])[1])
                        // //@ts-ignore
                        // .text(d.name)
                        // .attr('class', 'label');
                });

            });

            // Load and plot the points
            // svg.append('g')
            //     .selectAll('circle')
            //     .data(pointData)
            //     .enter()
            //     .append('circle')
            //     .attr('cx', (d) => projection([d.coordinates[1], d.coordinates[0]])[0])
            //     .attr('cy', (d) => projection([d.coordinates[1], d.coordinates[0]])[1])
            //     .attr('r', 5)
            //     .attr('fill', 'red')
            //     .attr('stroke', '#fff');
        // });
        });
    }

}
