/**
 * Expira API
 * The Expira RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Address } from './address';
import { PortalUser } from './portal-user';


export interface PortalAccount { 
    id?: number;
    createdAt?: string;
    lastUpdatedAt?: string;
    createdBy?: PortalUser;
    lastUpdatedBy?: PortalUser;
    dateDeactivated?: string;
    status?: PortalAccount.StatusEnum;
    reasonForStatusChange?: string;
    deactivatedBy?: PortalUser;
    name?: string;
    type?: PortalAccount.TypeEnum;
    code?: string;
    address?: Address;
}
export namespace PortalAccount {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
    export type TypeEnum = 'SYSTEM' | 'ORGANIZATION' | 'INDIVIDUAL' | 'USER' | 'PARTNER';
    export const TypeEnum = {
        SYSTEM: 'SYSTEM' as TypeEnum,
        ORGANIZATION: 'ORGANIZATION' as TypeEnum,
        INDIVIDUAL: 'INDIVIDUAL' as TypeEnum,
        USER: 'USER' as TypeEnum,
        PARTNER: 'PARTNER' as TypeEnum
    };
}


