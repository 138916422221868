<ng-template #loader>
    <loader></loader>
</ng-template>

<div class="container my-3 pb-4">
    <div class="d-flex align-items-center mb-5 small">
        <div>Home</div>
        <div class="text-dark fw-500"> / Election Results Uploads</div>
    </div>
    <div class="text-center blue-text">Election Results Uploads by region. Last updated
        : {{this.pollStatsData?.lastUpdated | datetime}}</div>
    <div class="d-flex align-items-center justify-content-center my-4">
        <div class="spinner-grow text-primary" style="width: 18px; height: 18px; background-color:#611F69"
             role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <h2 class="ms-3 ">2023 Election Result Uploads</h2>
    </div>
    <div class="row mt-5 mb-3 my-md-5 gx-4 gy-3">
        <div class="col-lg-3 col-md-12">
            <div class="col-md-12">
                <div class="dash-card text-white" style="background-color: #65B765;">
                    <div class="row">
                        <div class="col-auto">
                            <div class="dac-icon-wrapper">
                                <span class="material-symbols-outlined"> thumb_up </span>
                            </div>
                        </div>
                        <div class="col">
                            <div class="card-time text-end text-dark">
                                <!-- <div class="dash-box" style="background: #FFFDFA;"><span class="iconify" data-icon="iconoir:thumbs-up"></span></div> -->
                                <div style="font-size: 12px;" *ngIf="!this.loadingPollStats; else loader">Updated : {{this.pollStatsData?.lastUpdated | datetime}}</div>
                            </div>
                        </div>
                    </div>
                    <div style="font-size: 34px; font-weight: 600"
                         class="my-2 text-dark" *ngIf="!this.loadingPollStats; else loader">{{this.pollStatsData?.total?.ok?.amount}}</div>
                    <div class="d-flex justify-content-between text-dark">
                        <div style="font-size: 14px; font-weight: 500">Okay</div>
                        <div class="d-flex align-items-center">
                            <div><span class="iconify" data-icon="system-uicons:graph-box"></span></div>
                            <div *ngIf="!this.loadingPollStats; else loader">{{divideByHundred(this.pollStatsData?.total?.ok?.percentage) | percent}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <div class="row gy-3">
                <div class="col-lg-3">
                    <div class="dash-card text-white" style="background-color: #203E7A;">
                        <div class="row">
                            <div class="col-auto">
                                <div class="dac-icon-wrapper">
                                    <span class="material-symbols-outlined"> blur_on </span>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card-time text-end">
                                    <!-- <div class="dash-box" style="background: #FFFDFA;"><span class="iconify" data-icon="iconoir:thumbs-up"></span></div> -->
                                    <div style="font-size: 12px;" *ngIf="!this.loadingPollStats; else loader">Updated
                                        : {{this.pollStatsData?.lastUpdated | datetime}}</div>
                                </div>
                            </div>
                        </div>
                        <div style="font-size: 34px; font-weight: 600"
                             class="my-2" *ngIf="!this.loadingPollStats; else loader">{{this.pollStatsData?.total?.blur?.amount}}</div>
                        <div class="d-flex justify-content-between">
                            <div style="font-size: 14px; font-weight: 500">Blurred Imagery</div>
                            <div class="d-flex align-items-center">
                                <div><span class="iconify" data-icon="system-uicons:graph-box"></span></div>
                                <div *ngIf="!this.loadingPollStats; else loader">{{divideByHundred(this.pollStatsData?.total?.blur?.percentage) | percent}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="dash-card text-white" style="background-color: #611F69;">
                        <div class="row">
                            <div class="col-auto">
                                <div class="dac-icon-wrapper"><span class="material-symbols-outlined">hide_source</span>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card-time text-end">
                                    <!-- <div class="dash-box" style="background: #FFFDFA;"><span class="iconify" data-icon="iconoir:thumbs-up"></span></div> -->
                                    <div style="font-size: 12px; " *ngIf="!this.loadingPollStats; else loader">Updated
                                        : {{this.pollStatsData?.lastUpdated | datetime}}</div>
                                </div>
                            </div>
                        </div>
                        <div style="font-size: 34px; font-weight: 600"
                             class="my-2" *ngIf="!this.loadingPollStats; else loader">{{this.pollStatsData?.total?.nullified?.amount}}</div>
                        <div class="d-flex justify-content-between">
                            <div style="font-size: 14px; font-weight: 500">Null Form</div>
                            <div class="d-flex align-items-center">
                                <div><span class="iconify" data-icon="system-uicons:graph-box"></span></div>
                                <div *ngIf="!this.loadingPollStats; else loader">{{divideByHundred(this.pollStatsData?.total?.nullified?.percentage) | percent}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="dash-card text-dark" style="background-color: #FFA100;">
                        <div class="row">
                            <div class="col-auto">
                                <div class="dac-icon-wrapper"><span class="material-symbols-outlined">print_error</span>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card-time text-end">
                                    <!-- <div class="dash-box" style="background: #FFFDFA;"><span class="iconify" data-icon="iconoir:thumbs-up"></span></div> -->
                                    <div style="font-size: 12px;" *ngIf="!this.loadingPollStats; else loader">Updated
                                        : {{this.pollStatsData?.lastUpdated | datetime}}</div>
                                </div>
                            </div>
                        </div>
                        <div style="font-size: 34px; font-weight: 600"
                             class="my-2" *ngIf="!this.loadingPollStats; else loader">{{this.pollStatsData?.total?.tempM?.amount}}</div>
                        <div class="d-flex justify-content-between">
                            <div style="font-size: 14px; font-weight: 500">Machine Tampered</div>
                            <div class="d-flex align-items-center">
                                <div><span class="iconify" data-icon="system-uicons:graph-box"></span></div>
                                <div *ngIf="!this.loadingPollStats; else loader">{{divideByHundred(this.pollStatsData?.total?.tempM?.percentage) | percent}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3">
                    <div class="dash-card text-white" style="background-color: #1D1C1D;">
                        <div class="row">
                            <div class="col-auto">
                                <div class="dac-icon-wrapper">
                                    <span class="material-symbols-outlined"> background_replace </span>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card-time text-end">
                                    <!-- <div class="dash-box" style="background: #FFFDFA;"><span class="iconify" data-icon="iconoir:thumbs-up"></span></div> -->
                                    <div style="font-size: 12px;" *ngIf="!this.loadingPollStats; else loader">Updated
                                        : {{this.pollStatsData?.lastUpdated | datetime}}</div>
                                </div>
                            </div>
                        </div>
                        <div style="font-size: 34px; font-weight: 600"
                             class="my-2" *ngIf="!this.loadingPollStats; else loader">{{this.pollStatsData?.total?.tempH?.amount}}</div>
                        <div class="d-flex justify-content-between">
                            <div style="font-size: 14px; font-weight: 500">Human Tampered</div>
                            <div class="d-flex align-items-center">
                                <div><span class="iconify" data-icon="system-uicons:graph-box"></span></div>
                                <div *ngIf="!this.loadingPollStats; else loader">{{divideByHundred(this.pollStatsData?.total?.tempH?.percentage) | percent}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="my-2">
        <div class="card" style="border: none; background-color: transparent; ">
            <div class="card-body">
                <!-- Create an element where the map will take place -->
                <svg id="my_dataviz" width="1300" height="900"></svg>
                <div #tooltip class="tooltip"></div>
                <div #dataTooltip class="tooltip"></div>
            </div>
        </div>
    </div>

</div>
