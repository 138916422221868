import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import {DatetimePipe} from "./pipes/datetime.pipe";


@NgModule({
  declarations: [
    LoaderComponent,
    DatetimePipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,

  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    LoaderComponent,
    DatetimePipe
  ]
})
export class SharedModule { }
