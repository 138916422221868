/**
 * Expira API
 * The Expira RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CoordinateCondition { 
    coordinate?: Array<number>;
    pollCondition?: CoordinateCondition.PollConditionEnum;
    name?: string;
    url?: string;
}
export namespace CoordinateCondition {
    export type PollConditionEnum = 'OK' | 'BLUR' | 'TAMPER_H' | 'TAMPER_M' | 'NULL';
    export const PollConditionEnum = {
        OK: 'OK' as PollConditionEnum,
        BLUR: 'BLUR' as PollConditionEnum,
        TAMPER_H: 'TAMPER_H' as PollConditionEnum,
        TAMPER_M: 'TAMPER_M' as PollConditionEnum,
        NULL: 'NULL' as PollConditionEnum
    };
}


