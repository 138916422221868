/**
 * Expira API
 * The Expira RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MembershipPojo { 
    accountName?: string;
    accountCode?: string;
    accountType?: MembershipPojo.AccountTypeEnum;
    roles?: Array<string>;
}
export namespace MembershipPojo {
    export type AccountTypeEnum = 'SYSTEM' | 'ORGANIZATION' | 'INDIVIDUAL' | 'USER' | 'PARTNER';
    export const AccountTypeEnum = {
        SYSTEM: 'SYSTEM' as AccountTypeEnum,
        ORGANIZATION: 'ORGANIZATION' as AccountTypeEnum,
        INDIVIDUAL: 'INDIVIDUAL' as AccountTypeEnum,
        USER: 'USER' as AccountTypeEnum,
        PARTNER: 'PARTNER' as AccountTypeEnum
    };
}


