/**
 * Expira API
 * The Expira RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubDetails } from './sub-details';
import { PollState } from './poll-state';


export interface PollStat { 
    total?: PollState;
    states?: Array<SubDetails>;
    lastUpdated?: string;
}

