import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as d3 from "d3";
import {
    PollStat,
    PollStatisticsControllerService, SubDetails
} from "../../../../../sdk/corpernicusv-sdk";

@Component({
    selector: 'map-overview',
    templateUrl: './map-overview.component.html',
    styleUrls: ['./map-overview.component.css']
})
export class MapOverviewComponent implements OnInit {

    @ViewChild('tooltip') tooltip: ElementRef;
    @ViewChild('dataTooltip') dataTooltip: ElementRef;
    pollStatsData: PollStat;
    loadingPollStats: boolean;

    constructor(private pollStatisticsControllerService: PollStatisticsControllerService) {
    }

    ngOnInit(): void {
       this.loadApi();
    }

    loadApi(){
        this.loadingPollStats = true;
        this.pollStatisticsControllerService.getMainDashboardData({}).subscribe(res => {
            this.pollStatsData = res;
            this.drawMap(res);
            this.loadingPollStats = false;
        }, error => {
            this.loadingPollStats = false;
        })
    }
    drawMap(res: PollStat): void {
        // The svg
        const svg = d3.select("#my_dataviz").classed("interactive-svg", true);
        const width = +svg.attr("width");
        const height = +svg.attr("height");

        // Map and projection
        const projection = d3.geoNaturalEarth1()
            .scale(width * 9.3 / Math.PI)
            .translate([width / 10, height / 0.9]);

        // Load external data and boot
        d3.json("assets/js/nigeria_geojson.geojson").then((data) => {
            // Draw the map
            svg.append("g")
                .selectAll("path")
                //@ts-ignore
                .data(data.features)
                .enter().append("path")
                .attr("fill", "#9cd5c7")
                .style("stroke", "#fff")
                .attr("d", d3.geoPath()
                    .projection(projection)
                )
                .on('mouseover', (event, d) => {
                    // Change the fill color of the country
                    d3.select(event.currentTarget).attr("fill", "#2a9d8f");
                    //@ts-ignore
                    d3.select(event.currentTarget).append("text").text((d) => d?.name).attr("text-anchor", "middle")
                        .attr("alignment-baseline", "middle")
                        .attr("fill", "#000")
                        .attr("font-size", "12px");
                    //@ts-ignore
                    const stateData = res.states.find((state) => state?.name.toLowerCase() === d.properties?.state.toLowerCase());

                    //@ts-ignore
                    const propertyState = d.properties?.state?.toUpperCase();

                    // Update the percentage format
                    const formatPercentage = d3.format(".2f");

                        this.tooltip.nativeElement.style.left = '55rem';
                        this.tooltip.nativeElement.style.top = '10rem';
                        this.tooltip.nativeElement.style.visibility = "visible";

                        //@ts-ignore
                    if(stateData?.name)
                        this.tooltip.nativeElement.innerHTML = `<div class="dash-card text-dark bg-white" style="max-width: 400px;">
                                            <div style="font-size: 30px; font-weight: 600" class="my-2">
                                              ${propertyState} 
                                              <span>${'(' + this.totalStatePollingUnit(stateData) + ')'}</span>
                                            </div>
                                            <div>
                                              <div class="table-responsive">
                                                <table class="table">
                                                  <thead>
                                                    <tr style="color: rgba(0, 0, 0, 0.47);">
                                                      <th>Data</th>
                                                      <th>Percentage</th>
                                                      <th>Amount</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody style="font-weight: 500;">
                                                    <tr>
                                                      <th>OKAY</th>
                                                      <td>
                                                        <div class="d-flex align-items-center">
                                                          <div>${formatPercentage(stateData?.data?.ok?.percentage)}%</div>
                                                          <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #65B765;">
                                                            <div class="progress-bar" role="progressbar" style="width: ${stateData?.data?.ok?.percentage}%" aria-valuenow="${stateData?.data?.ok?.percentage}"
                                                              aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>${stateData?.data?.ok?.amount}</td>
                                                    </tr>
                                                    <tr>
                                                      <th>BLUR</th>
                                                      <td>
                                                        <div class="d-flex align-items-center">
                                                          <div>${formatPercentage(stateData?.data?.blur?.percentage)}%</div>
                                                          <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #203E7A;">
                                                            <div class="progress-bar" role="progressbar" style="width: ${stateData?.data?.blur?.percentage}%" aria-valuenow="${stateData?.data?.blur?.percentage}"
                                                              aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>${stateData?.data?.blur?.amount}</td>
                                                    </tr>
                                                    <tr>
                                                      <th>TAMH</th>
                                                      <td>
                                                        <div class="d-flex align-items-center">
                                                          <div>${formatPercentage(stateData?.data?.tempH?.percentage)}%</div>
                                                          <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #611F69;">
                                                            <div class="progress-bar" role="progressbar" style="width: ${stateData?.data?.tempH?.percentage}%" aria-valuenow="${stateData?.data?.tempH?.percentage}"
                                                              aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>${stateData?.data?.tempH?.amount}</td>
                                                    </tr>
                                                    <tr>
                                                      <th>TAMM</th>
                                                      <td>
                                                        <div class="d-flex align-items-center">
                                                          <div>${formatPercentage(stateData?.data?.tempM?.percentage)}%</div>
                                                          <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #FFA100;">
                                                            <div class="progress-bar" role="progressbar" style="width: ${stateData?.data?.tempM?.percentage}%" aria-valuenow="${stateData?.data?.tempM?.percentage}"
                                                              aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>${stateData?.data?.tempM?.amount}</td>
                                                    </tr>
                                                    <tr>
                                                      <th>NULL</th>
                                                      <td>
                                                        <div class="d-flex align-items-center">
                                                          <div>${formatPercentage(stateData?.data?.nullified?.percentage)}%</div>
                                                          <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #1D1C1D;">
                                                            <div class="progress-bar" role="progressbar" style="width: ${stateData?.data?.nullified?.percentage}%" aria-valuenow="${stateData?.data?.nullified?.percentage}"
                                                              aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>${stateData?.data?.nullified?.amount}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>`;

                    //@ts-ignore
                    if(!stateData?.name)
                    this.tooltip.nativeElement.innerHTML = `<div class="dash-card text-dark bg-white" style="max-width: 400px;">
                                            <div style="font-size: 30px; font-weight: 600" class="my-2">
                                              ${propertyState} 
                                          
                                            </div>    
                                          </div>`;
                })
                .on('mouseleave', (event, d) => {
                    // Revert the fill color of the state
                    d3.select(event.currentTarget).attr("fill", "#9cd5c7");

                    // Hide the tooltip if not hovering over a data point
                    const relatedTarget = event.relatedTarget;
                    if (!relatedTarget || !relatedTarget.classList.contains("data-point")) {
                        this.tooltip.nativeElement.style.visibility = "hidden";
                    }
                });


            //data points
            const points = svg.append("g")
                .selectAll("circle")
                .data(res.states)
                .enter()
                .append("g")
                .selectAll("circle")
                .data((d) => d.pollingUnits)
                .enter()
                .append("circle")
                .attr('cx', (d) => projection([d.coordinate[1], d.coordinate[0]])[0])
                .attr('cy', (d) => projection([d.coordinate[1], d.coordinate[0]])[1])
                .attr('r', 2.8)
                .attr('fill', (d) => {
                    if (d.pollCondition == null) {
                        return "#FFFF2E";
                    } else {
                        if (d.pollCondition.toUpperCase() === "TAMPER_H" || d.pollCondition.toUpperCase() === "TAMPER_M" || d.pollCondition.toUpperCase() === "BLUR") {
                            return "red";
                        } else if (d.pollCondition.toUpperCase() === "NULL") {
                            return "#FFFF2E";
                        } else if (d.pollCondition.toUpperCase() === "OK") {
                            return "green";
                        } else {
                            return "black";
                        }
                    }
                })
                .attr('stroke', '#fff')
                .attr('stroke-width', 1)
                .on('click', (event, d) => {
                    if (d?.url)
                        this.openLinkInNewTab(d?.url);
                })
                .on('mousemove', (event, d) => {
                    d3.select(event.currentTarget).attr("r", 8);

                    this.dataTooltip.nativeElement.style.left = '0';
                    this.dataTooltip.nativeElement.style.top = '20rem';
                    this.dataTooltip.nativeElement.style.visibility = "visible";
                    this.dataTooltip.nativeElement.innerHTML = ` <div class="dash-card text-dark bg-white" style="max-width: 400px;">
                          <div style="font-size: 30px; font-weight: 600" class="my-2">
                            Polling Unit
                          </div>
                          <div>
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr style="color: rgba(0, 0, 0, 0.47);">
                                    <th>Name</th>
                                    <th>Condition</th>
                                    <th *ngIf="${d?.url}">Url</th>
                                  </tr>
                                </thead>
                                <tbody style="font-weight: 500;">
                                  <tr>
                                    <td>${this.removeUnderScore(d?.name.toUpperCase())}</td>
                                    <td>${this.removeUnderScore(d?.pollCondition)}</td>
                                    <td *ngIf="${d?.url}"><a href="${d?.url}" target="_blank">Poll Url</a></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>`;

                    // Find the state based on the latitude and longitude of the data point
                    const latitude = d.coordinate[0];
                    const longitude = d.coordinate[1];
                    const stateData = res.states.find((state) => {
                        const pollingUnit = state.pollingUnits.find((unit) => {
                            return unit.coordinate[0] === latitude && unit.coordinate[1] === longitude;
                        });
                        return pollingUnit !== undefined;
                    });
                    const formatPercentage = d3.format(".2f");

                    if (stateData) {
                        this.tooltip.nativeElement.style.left = '55rem';
                        this.tooltip.nativeElement.style.top = '10rem';
                        this.tooltip.nativeElement.style.visibility = "visible";
                        this.tooltip.nativeElement.innerHTML = `<div class="dash-card text-dark bg-white" style="max-width: 400px;">
                                            <div style="font-size: 30px; font-weight: 600" class="my-2">
                                              ${stateData?.name.toUpperCase()} 
                                              <span>${'(' + this.totalStatePollingUnit(stateData) + ')'}</span>
                                            </div>
                                            <div>
                                              <div class="table-responsive">
                                                <table class="table">
                                                  <thead>
                                                    <tr style="color: rgba(0, 0, 0, 0.47);">
                                                      <th>Data</th>
                                                      <th>Percentage</th>
                                                      <th>Amount</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody style="font-weight: 500;">
                                                    <tr>
                                                      <th>OKAY</th>
                                                      <td>
                                                        <div class="d-flex align-items-center">
                                                          <div>${formatPercentage(stateData?.data?.ok?.percentage)}%</div>
                                                          <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #65B765;">
                                                            <div class="progress-bar" role="progressbar" style="width: ${stateData?.data?.ok?.percentage}%" aria-valuenow="${stateData?.data?.ok?.percentage}"
                                                              aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>${stateData?.data?.ok?.amount}</td>
                                                    </tr>
                                                    <tr>
                                                      <th>BLUR</th>
                                                      <td>
                                                        <div class="d-flex align-items-center">
                                                          <div>${formatPercentage(stateData?.data?.blur?.percentage)}%</div>
                                                          <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #203E7A;">
                                                            <div class="progress-bar" role="progressbar" style="width: ${stateData?.data?.blur?.percentage}%" aria-valuenow="${stateData?.data?.blur?.percentage}"
                                                              aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>${stateData?.data?.blur?.amount}</td>
                                                    </tr>
                                                    <tr>
                                                      <th>TAMH</th>
                                                      <td>
                                                        <div class="d-flex align-items-center">
                                                          <div>${formatPercentage(stateData?.data?.tempH?.percentage)}%</div>
                                                          <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #611F69;">
                                                            <div class="progress-bar" role="progressbar" style="width: ${stateData?.data?.tempH?.percentage}%" aria-valuenow="${stateData?.data?.tempH?.percentage}"
                                                              aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>${stateData?.data?.tempH?.amount}</td>
                                                    </tr>
                                                    <tr>
                                                      <th>TAMM</th>
                                                      <td>
                                                        <div class="d-flex align-items-center">
                                                          <div>${formatPercentage(stateData?.data?.tempM?.percentage)}%</div>
                                                          <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #FFA100;">
                                                            <div class="progress-bar" role="progressbar" style="width: ${stateData?.data?.tempM?.percentage}%" aria-valuenow="${stateData?.data?.tempM?.percentage}"
                                                              aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>${stateData?.data?.tempM?.amount}</td>
                                                    </tr>
                                                    <tr>
                                                      <th>NULL</th>
                                                      <td>
                                                        <div class="d-flex align-items-center">
                                                          <div>${formatPercentage(stateData?.data?.nullified?.percentage)}%</div>
                                                          <div class="progress ms-2" style="width: 120px; --bs-progress-bar-bg: #1D1C1D;">
                                                            <div class="progress-bar" role="progressbar" style="width: ${stateData?.data?.nullified?.percentage}%" aria-valuenow="${stateData?.data?.nullified?.percentage}"
                                                              aria-valuemin="0" aria-valuemax="100"></div>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>${stateData?.data?.nullified?.amount}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>`;
                    }
                }).on('mouseleave', (event, d) => {
                    d3.select(event.currentTarget).attr("r", 2.4);
                    this.tooltip.nativeElement.style.visibility = "hidden";
                    // Hide the tooltip if not hovering over a map path
                    const relatedTarget = event.relatedTarget;
                    if (!relatedTarget || !relatedTarget.classList.contains("map-path")) {
                        this.dataTooltip.nativeElement.style.visibility = "hidden";
                    }
                });
        });
    }

    openLinkInNewTab(url: string) {
        window.open(url, '_blank');
    }

    totalStatePollingUnit(stateData: SubDetails) {
        return stateData?.data?.nullified?.amount
            + stateData?.data?.tempM?.amount + stateData?.data?.tempH?.amount
            + stateData?.data?.blur?.amount + stateData?.data?.ok?.amount;
    }

    divideByHundred(value: number) {
        if (value != null)
            return value / 100;
        return 0;
    }

    removeUnderScore(value: string) {
        return value.replaceAll('_', ' ');
    }
}
