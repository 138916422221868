<div class="wrapper">
    <div class="log-in container">
      <div class="row ">
        <div class="col-lg-5 col-md-6">
          <div class="mb-4 header-text">
            <h1 class="">Welcome To</h1>
            <h1 class="">Corpernicus!</h1>
          </div>
          <form class="login-input" action="index.html" method="post">
            <p class="">Enter your code to login</p>
            <div class="form-floating mb-3 mt-3">
              <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
              <label for="floatingInput" class="text-muted opacity-1">Your code here</label>
            </div>

            <div class="btn btn-dark btn-large d-grid d-md-block p-3 mt-4 mb-3">
              Login
            </div>

            <!-- <div class="d-flex justify-content-center">
              <p>&copy; 2023 corpernicus. All rights reserved</p>
            </div> -->
          </form>

        </div>
        <div class="col">

        </div>
      </div>
    </div>
  </div>