/**
 * Expira API
 * The Expira RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PortalAccountDto { 
    name: string;
    type: PortalAccountDto.TypeEnum;
    email?: string;
    code?: string;
    phoneNumber?: string;
    altPhoneNumber?: string;
    streetName?: string;
    houseNumber?: string;
    zipCode?: string;
}
export namespace PortalAccountDto {
    export type TypeEnum = 'SYSTEM' | 'ORGANIZATION' | 'INDIVIDUAL' | 'USER' | 'PARTNER';
    export const TypeEnum = {
        SYSTEM: 'SYSTEM' as TypeEnum,
        ORGANIZATION: 'ORGANIZATION' as TypeEnum,
        INDIVIDUAL: 'INDIVIDUAL' as TypeEnum,
        USER: 'USER' as TypeEnum,
        PARTNER: 'PARTNER' as TypeEnum
    };
}


