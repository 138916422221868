<footer >
    <div class="container" >
        <div class="text-center" >
            <!-- <div class="fs-20 mb-3 sec-font" style="font-weight: 600;  opacity: 0.8;">Corpernicus</div> -->
            <div class="fs-16 mb-4">Election results files archive</div>
            <div class="fs-24 mb-4">Election Results Update</div>
            <div class="mb-3 mt-4 fs-18" style="opacity: 0.8;">IN ASSOCIATION WITH</div>
            <div class="my-4">
                <img src="../assets/img/site-logo-white.svg" width="290px" alt="">
            </div>
        </div>
        <div class="footer-blue mt-5">
            <div class="d-flex align-items-center justify-content-between">
                <!-- <div>Copyright 2023 - Byteworks, All Rights Reserved</div> -->
                <div class="d-flex align-items-center">
                    <div>Privacy Policy</div>
                    <div class="ms-2">Cookie Policy</div>
                    <div class="ms-2">Terms and Conditions</div>
                </div>
            </div>
        </div>
    </div>
</footer>