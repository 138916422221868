/**
 * Expira API
 * The Expira RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccountMembershipPojo } from './account-membership-pojo';


export interface UserPojo { 
    displayName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    mobileNumber?: string;
    username?: string;
    userId?: string;
    status?: UserPojo.StatusEnum;
    dateCreated?: string;
    lastUpdated?: string;
    accountCode?: string;
    createdBy?: string;
    lastUpdatedBy?: string;
    displayPictureId?: number;
    requiresPasswordUpdate?: boolean;
    authToken?: string;
    id?: number;
    emailVerified?: boolean;
    accounts?: Array<AccountMembershipPojo>;
}
export namespace UserPojo {
    export type StatusEnum = 'ACTIVE' | 'INACTIVE';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum
    };
}


