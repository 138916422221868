import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { CountryControllerService } from './api/country-controller.service';
import { EmailValidationControllerService } from './api/email-validation-controller.service';
import { FileControllerService } from './api/file-controller.service';
import { OtpControllerService } from './api/otp-controller.service';
import { PollStatisticsControllerService } from './api/poll-statistics-controller.service';
import { StateControllerService } from './api/state-controller.service';
import { UiControllerService } from './api/ui-controller.service';
import { UserControllerService } from './api/user-controller.service';
import { UserManagementControllerService } from './api/user-management-controller.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
