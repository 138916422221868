<header class="">
    <nav class="navbar navbar-expand-lg ">
        <div class="container">
            <a href="">
                <img src="/assets/img/site-logo.svg" alt="">  
            </a>
          <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav ms-auto">
                <li class="nav-item "> <a class="nav-link active" aria-current="page" > API Documentation</a>
                </li>
                <li class="nav-item ms-4">
                    <button class="btn btn-primary-outline" >Sign in <span class="iconify" data-icon="carbon:port-input"></span></button>
                </li>
                <li class="nav-item ms-3">
                    <button class="btn btn-primary" >Get Started</button>
                </li>
                </ul>
          </div> -->
        </div>
    </nav>
</header>
