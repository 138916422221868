import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule, KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { ToastrModule } from 'ngx-toastr';
import { filter, mergeMap, of } from 'rxjs';
import { CodeInputModule } from 'angular-code-input';
import { CountdownConfig, CountdownGlobalConfig } from 'ngx-countdown';
import { LottieModule } from 'ngx-lottie';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgChartsConfiguration, NgChartsModule } from 'ng2-charts';
import { JoyrideModule } from 'ngx-joyride';
import { NgxIntlTelInputModule } from '@capgo/ngx-intl-tel-input';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgOptimizedImage } from '@angular/common';
import { WebcamModule } from 'ngx-webcam';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutesRoutingModule } from './app-routes-routing.module';
import { MapVisualizationComponent } from './modules/extranet/map-visualization/map-visualization.component';
import { LandingPageComponent } from './modules/extranet/landing-page/landing-page.component';
import { SiteHeaderComponent } from './modules/layout/site-header/site-header.component';
import { SiteFooterComponent } from './modules/layout/site-footer/site-footer.component';
import { MapOverviewComponent } from './modules/extranet/map-overview/map-overview.component';
import { DetailedViewComponent } from './modules/extranet/detailed-view/detailed-view.component';
// import { DetailsPopComponent } from './modules/extranet/details-pop/details-pop.component';
import {BASE_PATH} from "../../sdk/corpernicusv-sdk";
import {SharedModule} from "./shared/shared.module";


//declare const ;

/**
 *
 */
export function lottiePlayerFactory(): any {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}

/**
 *
 * @param keycloak
 * @param authenticationService
 */
// function initializeKeycloak(
//   keycloak: KeycloakService,
//   authenticationService: AuthenticationService
// ): () => Promise<boolean> {
//   keycloak.keycloakEvents$
//     .pipe(
//       filter((value) => value.type === KeycloakEventType.OnAuthSuccess),
//       mergeMap((value) => authenticationService.fetchUser())
//     )
//     .subscribe();
//   const orgOpen = window.open;
//   window.open = function (
//     url?: string,
//     target?: string,
//     features?: string,
//     replace?: boolean
//   ): WindowProxy {
//     if (url && url.includes(environment.byteoneReportUrl) && !url.includes('access_token')) {
//       keycloak
//         .getToken()
//         .then((value) => {
//           if (value) {
//             const updatedUrl = `${url}${url.includes('?') ? '&' : '?'}access_token=${value}`;
//             return orgOpen(updatedUrl, target, features);
//           } else {
//             return orgOpen(url, target, features);
//           }
//         })
//         .catch((reason) => console.log(reason));
//     } else {
//       return orgOpen(url, target, features);
//     }
//     return null;
//   };
//   return (): Promise<boolean> => {
//     // if (authenticationService.isMobileApp) {
//     //   return of(false).toPromise();
//     // }
//     return keycloak.init({
//       config: environment.keycloakConfig,
//       initOptions: {
//         enableLogging: !environment.production,
//         onLoad: 'check-sso',
//         silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
//       }
//     });
//   };
// }

// AoT requires an exported function for factories
/**
 *
 * @param http
 */
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

/**
 *
 */
function countdownConfigFactory(): CountdownConfig {
  return { format: `mm:ss` };
}

@NgModule({
  declarations: [
    AppComponent,
    MapVisualizationComponent,
    LandingPageComponent,
    SiteHeaderComponent,
    SiteFooterComponent,
    MapOverviewComponent,
    DetailedViewComponent,
    // DetailsPopComponent,
  ],
  imports: [
    BrowserModule,
    LottieModule.forRoot({ player: lottiePlayerFactory }),
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    KeycloakAngularModule,
    HttpClientModule,
    FormsModule,
    WebcamModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutesRoutingModule,
    ModalModule.forRoot(),
    CodeInputModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TooltipModule,
    PdfViewerModule,
    AlertModule,
    PaginationModule,
    BsDatepickerModule,
    NgChartsModule,
    JoyrideModule.forRoot(),
    NgxIntlTelInputModule,
    NgOptimizedImage,
    PopoverModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CookieService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeKeycloak,
    //   multi: true,
    //   deps: [KeycloakService, AuthenticationService]
    // },
    {
      provide: BASE_PATH,
      useValue: environment.apiBaseUrl
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpInterceptorService,
    //   multi: true
    // },
    { provide: CountdownGlobalConfig, useFactory: countdownConfigFactory },
    // {
    //   provide: CUSTOM_ERROR_MESSAGES,
    //   useFactory: () => {
    //     return new CustomErrorMessageImpl().getErrorMessages();
    //   }, multi: true
    // },
    { provide: NgChartsConfiguration, useValue: { generateColors: false } },
    TranslatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
