import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './modules/extranet/landing-page/landing-page.component';
import { MapOverviewComponent } from './modules/extranet/map-overview/map-overview.component';
import { DetailedViewComponent } from './modules/extranet/detailed-view/detailed-view.component';
import {MapVisualizationComponent} from "./modules/extranet/map-visualization/map-visualization.component";

const routes: Routes = [
  {
    path: 'fff',
    component: MapVisualizationComponent,
    pathMatch: 'full',
  },

  {
    path: '',
    component: MapOverviewComponent,
    pathMatch: 'full',
  },

  {
    path: 'details',
    component: DetailedViewComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutesRoutingModule {}
