<div class="container my-5 pb-4">
    <div class="d-flex align-items-center mb-5">
        <div>Home </div>
        <div class="text-dark fw-500"> / Election Results Uploads </div>
        <div class="text-dark fw-500"> / Kaduna </div>
    </div>
    <div class="d-flex align-items-center justify-content-center my-4">
        <div class="spinner-grow text-primary" style="width: 18px; height: 18px; background-color:#611F69" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        <h2 class="ms-3">Kaduna State Uploads</h2>
    </div>
    <div class="row my-3 pb-5 gy-3">
        <div class="col-md-6">
            <div class="form-group" style="max-width: 380px;">
                <label class="form-label">Select State</label>
                <select class="form-control" formControlName="status">
                <option value="">-- All--</option>
                <option  value=""></option>
                </select>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group  ms-auto" style="max-width: 380px;">
                <label class="form-label">Select LGA</label>
                <select class="form-control" formControlName="status">
                <option value="">-- All--</option>
                <option  value=""></option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
      <div class="col-12">
        <a class="am-dashboard-card pointer">
          <div class="row w-100 me-0 mb-2 p-0 align-items-center">
            <div class="col d-flex align-items-center pointer gap-2">
              <button class="btn float-start fw-bold">WARD</button>

              <div class="input-group mx-4">
                <label class="input-group-text" for="inputGroupSelect01">FIlter By:</label>
                <select class="form-select" id="inputGroupSelect01">
                  <option selected>All</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>
            <div class="col">
              <div class="card-time float-end d-flex gap-1">
                <button class="btn mx-1 d-flex align-items-center">
                  <div class="spinner-grow" role="status" style="background-color: #3AD29F;"></div>
                  <span>OK</span>
                </button>
                <button class="btn mx-1 d-flex align-items-center">
                  <div class="spinner-grow" role="status" style="background-color: #1D1C1D75;"></div>
                  <span>BLUR</span>
                </button>
                <button class="btn mx-1 d-flex align-items-center">
                  <div class="spinner-grow" role="status" style="background-color: #611F69;"></div>
                  <span>TH</span>
                </button>
                <button class="btn mx-1 d-flex align-items-center">
                  <div class="spinner-grow" role="status" style="background-color: #FFA100;"></div>
                  <span>TM</span>
                </button>
                <button class="btn mx-1 d-flex align-items-center">

                  <div class="spinner-grow" role="status" style="background-color: #1D1C1D;"></div>
                  <span>Null</span>

                </button>
              </div>
            </div>
          </div>
          <div class="card border-0" style="overflow-x: auto">
            <div class="card-body ">
              <img src="../assets/img/chart.png" alt="">
            </div>
          </div>
        </a>
      </div>

      <div class="col-md-4">
        <div class="row">
          <div class="col-12">
            <div class="dashboard-card">

              <div class="row mt-2 mb-4">
                <p class="text-start fw-500 m-0"> Data By Percentage</p>
                <small class="text-start">Last Updated : 12th, Oct, 2022</small>
              </div>
              <div class="row">
                <div class="col">
                  <div class="card border-0">
                    <div class="card-body overflow-x-hidden">

                      <div>
                        <div class="d-block justify-content-center align-items-center">
                          <div class="text-center">
                            <img src="../assets/img/pie.png" class="img-fluid d-block" width="350" />
                            <p class="my-2 d-block text-muted opacity-75 d-none">
                              Looks like there's not enough data yet.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-md-8">
        <a class="dashboard-card ">
          <div class="row w-100">
            <div class="col">
              <button class="btn float-start fw-500"> Polling Unit</button>
            </div>
            <div class="col">
              <div class="card-time text-end">
                <div class="input-group mx-4">
                  <label class="input-group-text" for="inputGroupSelect01">FIlter By:</label>
                  <select class="form-select" id="inputGroupSelect01">
                    <option selected>All</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="card border-0" style="overflow-x: auto;">
            <div class="card-body">
               <img src="../assets/img/bar.png" alt=""  style="height: inherit; width: 96%">
            </div>
          </div>

        </a>
      </div>
    </div>
  </div>