/**
 * Expira API
 * The Expira RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AccountMembershipPojo { 
    accountName?: string;
    accountCode?: string;
    accountId?: number;
    enableSubscriptions?: boolean;
    accountType?: AccountMembershipPojo.AccountTypeEnum;
    roles?: Set<string>;
    permissions?: Set<AccountMembershipPojo.PermissionsEnum>;
    accountLogoId?: number;
}
export namespace AccountMembershipPojo {
    export type AccountTypeEnum = 'SYSTEM' | 'ORGANIZATION' | 'INDIVIDUAL' | 'USER' | 'PARTNER';
    export const AccountTypeEnum = {
        SYSTEM: 'SYSTEM' as AccountTypeEnum,
        ORGANIZATION: 'ORGANIZATION' as AccountTypeEnum,
        INDIVIDUAL: 'INDIVIDUAL' as AccountTypeEnum,
        USER: 'USER' as AccountTypeEnum,
        PARTNER: 'PARTNER' as AccountTypeEnum
    };
    export type PermissionsEnum = 'CREATE_USER' | 'UPDATE_USER' | 'VIEW_USER' | 'DEACTIVATE_USER' | 'UPDATE_BANK_ACCOUNT' | 'CREATE_BANK_ACCOUNT' | 'VIEW_BANK_ACCOUNT' | 'UPDATE_SETTING' | 'VIEW_SETTING' | 'VIEW_LOG' | 'REVIEW_REQUISITION' | 'CREATE_REQUISITION' | 'VIEW_PARTNER_REQUEST' | 'REVIEW_PARTNER_REQUEST';
    export const PermissionsEnum = {
        CREATE_USER: 'CREATE_USER' as PermissionsEnum,
        UPDATE_USER: 'UPDATE_USER' as PermissionsEnum,
        VIEW_USER: 'VIEW_USER' as PermissionsEnum,
        DEACTIVATE_USER: 'DEACTIVATE_USER' as PermissionsEnum,
        UPDATE_BANK_ACCOUNT: 'UPDATE_BANK_ACCOUNT' as PermissionsEnum,
        CREATE_BANK_ACCOUNT: 'CREATE_BANK_ACCOUNT' as PermissionsEnum,
        VIEW_BANK_ACCOUNT: 'VIEW_BANK_ACCOUNT' as PermissionsEnum,
        UPDATE_SETTING: 'UPDATE_SETTING' as PermissionsEnum,
        VIEW_SETTING: 'VIEW_SETTING' as PermissionsEnum,
        VIEW_LOG: 'VIEW_LOG' as PermissionsEnum,
        REVIEW_REQUISITION: 'REVIEW_REQUISITION' as PermissionsEnum,
        CREATE_REQUISITION: 'CREATE_REQUISITION' as PermissionsEnum,
        VIEW_PARTNER_REQUEST: 'VIEW_PARTNER_REQUEST' as PermissionsEnum,
        REVIEW_PARTNER_REQUEST: 'REVIEW_PARTNER_REQUEST' as PermissionsEnum
    };
}


