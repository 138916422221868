/**
 * Expira API
 * The Expira RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { EditUserDto } from '../model/models';
import { NameIdPojo } from '../model/models';
import { PortalAccountDto } from '../model/models';
import { PortalUserDto } from '../model/models';
import { PortalUserPojo } from '../model/models';
import { QueryResultsPortalUserSearchResponsePojo } from '../model/models';
import { RegisterUserDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface ActivateUserRequestParams {
    userId: number;
}

export interface AddRoleToAccountRequestParams {
    userId: string;
    accountCode: string;
    role: string;
}

export interface DeactivateUserRequestParams {
    userId: number;
}

export interface GetUserDetailsRequestParams {
    id: number;
}

export interface GetUserDetailsByEmailRequestParams {
    email: string;
}

export interface RegisterRequestParams {
    registerUserDto: RegisterUserDto;
}

export interface RemoveRoleFromAccountRequestParams {
    userId: string;
    accountCode: string;
    role: string;
}

export interface RequestRegistrationOtpRequestParams {
    email: string;
    firstName: string;
}

export interface SearchPortalUsersRequestParams {
    reasonForStatusChange?: string;
    lastName?: string;
    gender?: 'MALE' | 'FEMALE' | 'UNSPECIFIED' | 'OTHER';
    generatedPassword?: string;
    lastUpdatedAt?: string;
    displayName?: string;
    deactivatedBy?: string;
    createdAt?: string;
    preferredName?: string;
    id?: number;
    email?: string;
    image?: string;
    lastUpdatedBy?: string;
    setupComplete?: boolean;
    address?: string;
    userVerified?: boolean;
    dateOfBirth?: string;
    userId?: string;
    firstName?: string;
    emailVerified?: boolean;
    otherNames?: string;
    createdBy?: string;
    name?: string;
    dateDeactivated?: string;
    username?: string;
    status?: 'ACTIVE' | 'INACTIVE';
    accountCode?: string;
    phoneNumber?: string;
    role?: string;
    offset?: number;
    accountType?: 'SYSTEM' | 'ORGANIZATION' | 'INDIVIDUAL' | 'USER' | 'PARTNER';
    limit?: number;
    createdBefore?: string;
    createdAfter?: string;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface UpdateUserDetailsRequestParams {
    id: number;
    editUserDto: EditUserDto;
}

export interface ValidateRequestParams {
    field: Array<string>;
    value: Array<string>;
    accountType: 'SYSTEM' | 'ORGANIZATION' | 'INDIVIDUAL' | 'USER' | 'PARTNER';
}


@Injectable({
  providedIn: 'root'
})
export class UserManagementControllerService {

    protected basePath = 'http://copernicusv.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateUser(requestParameters: ActivateUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<string>;
    public activateUser(requestParameters: ActivateUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<string>>;
    public activateUser(requestParameters: ActivateUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<string>>;
    public activateUser(requestParameters: ActivateUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const userId = requestParameters.userId;
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling activateUser.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (userId !== undefined && userId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userId, 'userId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<string>(`${this.configuration.basePath}/users/activate`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addRoleToAccount(requestParameters: AddRoleToAccountRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public addRoleToAccount(requestParameters: AddRoleToAccountRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public addRoleToAccount(requestParameters: AddRoleToAccountRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public addRoleToAccount(requestParameters: AddRoleToAccountRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const userId = requestParameters.userId;
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling addRoleToAccount.');
        }
        const accountCode = requestParameters.accountCode;
        if (accountCode === null || accountCode === undefined) {
            throw new Error('Required parameter accountCode was null or undefined when calling addRoleToAccount.');
        }
        const role = requestParameters.role;
        if (role === null || role === undefined) {
            throw new Error('Required parameter role was null or undefined when calling addRoleToAccount.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (accountCode !== undefined && accountCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>accountCode, 'accountCode');
        }
        if (role !== undefined && role !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>role, 'role');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/users/${encodeURIComponent(String(userId))}/add-role`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deactivateUser(requestParameters: DeactivateUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<string>;
    public deactivateUser(requestParameters: DeactivateUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<string>>;
    public deactivateUser(requestParameters: DeactivateUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<string>>;
    public deactivateUser(requestParameters: DeactivateUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const userId = requestParameters.userId;
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling deactivateUser.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (userId !== undefined && userId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userId, 'userId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<string>(`${this.configuration.basePath}/users/deactivate`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getLoggedInUser(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PortalUserDto>;
    public getLoggedInUser(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PortalUserDto>>;
    public getLoggedInUser(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PortalUserDto>>;
    public getLoggedInUser(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PortalUserDto>(`${this.configuration.basePath}/users/user`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPortalAccount(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PortalAccountDto>;
    public getPortalAccount(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PortalAccountDto>>;
    public getPortalAccount(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PortalAccountDto>>;
    public getPortalAccount(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PortalAccountDto>(`${this.configuration.basePath}/users/account`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPortalAccounts(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<PortalAccountDto>>;
    public getPortalAccounts(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<PortalAccountDto>>>;
    public getPortalAccounts(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<PortalAccountDto>>>;
    public getPortalAccounts(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<PortalAccountDto>>(`${this.configuration.basePath}/users/accounts`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRoles(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<Array<NameIdPojo>>;
    public getRoles(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<Array<NameIdPojo>>>;
    public getRoles(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<Array<NameIdPojo>>>;
    public getRoles(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<Array<NameIdPojo>>(`${this.configuration.basePath}/users/roles`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserDetails(requestParameters: GetUserDetailsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PortalUserPojo>;
    public getUserDetails(requestParameters: GetUserDetailsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PortalUserPojo>>;
    public getUserDetails(requestParameters: GetUserDetailsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PortalUserPojo>>;
    public getUserDetails(requestParameters: GetUserDetailsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUserDetails.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PortalUserPojo>(`${this.configuration.basePath}/users/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserDetailsByEmail(requestParameters: GetUserDetailsByEmailRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PortalUserPojo>;
    public getUserDetailsByEmail(requestParameters: GetUserDetailsByEmailRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PortalUserPojo>>;
    public getUserDetailsByEmail(requestParameters: GetUserDetailsByEmailRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PortalUserPojo>>;
    public getUserDetailsByEmail(requestParameters: GetUserDetailsByEmailRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const email = requestParameters.email;
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling getUserDetailsByEmail.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (email !== undefined && email !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>email, 'email');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<PortalUserPojo>(`${this.configuration.basePath}/users/details`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public register(requestParameters: RegisterRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public register(requestParameters: RegisterRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public register(requestParameters: RegisterRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public register(requestParameters: RegisterRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const registerUserDto = requestParameters.registerUserDto;
        if (registerUserDto === null || registerUserDto === undefined) {
            throw new Error('Required parameter registerUserDto was null or undefined when calling register.');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/users/registration/submit`,
            registerUserDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeRoleFromAccount(requestParameters: RemoveRoleFromAccountRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public removeRoleFromAccount(requestParameters: RemoveRoleFromAccountRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public removeRoleFromAccount(requestParameters: RemoveRoleFromAccountRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public removeRoleFromAccount(requestParameters: RemoveRoleFromAccountRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const userId = requestParameters.userId;
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling removeRoleFromAccount.');
        }
        const accountCode = requestParameters.accountCode;
        if (accountCode === null || accountCode === undefined) {
            throw new Error('Required parameter accountCode was null or undefined when calling removeRoleFromAccount.');
        }
        const role = requestParameters.role;
        if (role === null || role === undefined) {
            throw new Error('Required parameter role was null or undefined when calling removeRoleFromAccount.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (accountCode !== undefined && accountCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>accountCode, 'accountCode');
        }
        if (role !== undefined && role !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>role, 'role');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/users/${encodeURIComponent(String(userId))}/remove-role`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public requestRegistrationOtp(requestParameters: RequestRegistrationOtpRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public requestRegistrationOtp(requestParameters: RequestRegistrationOtpRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public requestRegistrationOtp(requestParameters: RequestRegistrationOtpRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public requestRegistrationOtp(requestParameters: RequestRegistrationOtpRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const email = requestParameters.email;
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling requestRegistrationOtp.');
        }
        const firstName = requestParameters.firstName;
        if (firstName === null || firstName === undefined) {
            throw new Error('Required parameter firstName was null or undefined when calling requestRegistrationOtp.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (email !== undefined && email !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>email, 'email');
        }
        if (firstName !== undefined && firstName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>firstName, 'firstName');
        }

        let headers = this.defaultHeaders;

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/users/registration/requestotp`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPortalUsers(requestParameters: SearchPortalUsersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsPortalUserSearchResponsePojo>;
    public searchPortalUsers(requestParameters: SearchPortalUsersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsPortalUserSearchResponsePojo>>;
    public searchPortalUsers(requestParameters: SearchPortalUsersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsPortalUserSearchResponsePojo>>;
    public searchPortalUsers(requestParameters: SearchPortalUsersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const reasonForStatusChange = requestParameters.reasonForStatusChange;
        const lastName = requestParameters.lastName;
        const gender = requestParameters.gender;
        const generatedPassword = requestParameters.generatedPassword;
        const lastUpdatedAt = requestParameters.lastUpdatedAt;
        const displayName = requestParameters.displayName;
        const deactivatedBy = requestParameters.deactivatedBy;
        const createdAt = requestParameters.createdAt;
        const preferredName = requestParameters.preferredName;
        const id = requestParameters.id;
        const email = requestParameters.email;
        const image = requestParameters.image;
        const lastUpdatedBy = requestParameters.lastUpdatedBy;
        const setupComplete = requestParameters.setupComplete;
        const address = requestParameters.address;
        const userVerified = requestParameters.userVerified;
        const dateOfBirth = requestParameters.dateOfBirth;
        const userId = requestParameters.userId;
        const firstName = requestParameters.firstName;
        const emailVerified = requestParameters.emailVerified;
        const otherNames = requestParameters.otherNames;
        const createdBy = requestParameters.createdBy;
        const name = requestParameters.name;
        const dateDeactivated = requestParameters.dateDeactivated;
        const username = requestParameters.username;
        const status = requestParameters.status;
        const accountCode = requestParameters.accountCode;
        const phoneNumber = requestParameters.phoneNumber;
        const role = requestParameters.role;
        const offset = requestParameters.offset;
        const accountType = requestParameters.accountType;
        const limit = requestParameters.limit;
        const createdBefore = requestParameters.createdBefore;
        const createdAfter = requestParameters.createdAfter;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (reasonForStatusChange !== undefined && reasonForStatusChange !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>reasonForStatusChange, 'reasonForStatusChange');
        }
        if (lastName !== undefined && lastName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastName, 'lastName');
        }
        if (gender !== undefined && gender !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>gender, 'gender');
        }
        if (generatedPassword !== undefined && generatedPassword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>generatedPassword, 'generatedPassword');
        }
        if (lastUpdatedAt !== undefined && lastUpdatedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedAt, 'lastUpdatedAt');
        }
        if (displayName !== undefined && displayName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>displayName, 'displayName');
        }
        if (deactivatedBy !== undefined && deactivatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>deactivatedBy, 'deactivatedBy');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (preferredName !== undefined && preferredName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>preferredName, 'preferredName');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (email !== undefined && email !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>email, 'email');
        }
        if (image !== undefined && image !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>image, 'image');
        }
        if (lastUpdatedBy !== undefined && lastUpdatedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>lastUpdatedBy, 'lastUpdatedBy');
        }
        if (setupComplete !== undefined && setupComplete !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>setupComplete, 'setupComplete');
        }
        if (address !== undefined && address !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>address, 'address');
        }
        if (userVerified !== undefined && userVerified !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userVerified, 'userVerified');
        }
        if (dateOfBirth !== undefined && dateOfBirth !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateOfBirth, 'dateOfBirth');
        }
        if (userId !== undefined && userId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>userId, 'userId');
        }
        if (firstName !== undefined && firstName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>firstName, 'firstName');
        }
        if (emailVerified !== undefined && emailVerified !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>emailVerified, 'emailVerified');
        }
        if (otherNames !== undefined && otherNames !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>otherNames, 'otherNames');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (name !== undefined && name !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>name, 'name');
        }
        if (dateDeactivated !== undefined && dateDeactivated !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>dateDeactivated, 'dateDeactivated');
        }
        if (username !== undefined && username !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>username, 'username');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (accountCode !== undefined && accountCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>accountCode, 'accountCode');
        }
        if (phoneNumber !== undefined && phoneNumber !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>phoneNumber, 'phoneNumber');
        }
        if (role !== undefined && role !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>role, 'role');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (accountType !== undefined && accountType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>accountType, 'accountType');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsPortalUserSearchResponsePojo>(`${this.configuration.basePath}/users/search`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserDetails(requestParameters: UpdateUserDetailsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<PortalUserPojo>;
    public updateUserDetails(requestParameters: UpdateUserDetailsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<PortalUserPojo>>;
    public updateUserDetails(requestParameters: UpdateUserDetailsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<PortalUserPojo>>;
    public updateUserDetails(requestParameters: UpdateUserDetailsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateUserDetails.');
        }
        const editUserDto = requestParameters.editUserDto;
        if (editUserDto === null || editUserDto === undefined) {
            throw new Error('Required parameter editUserDto was null or undefined when calling updateUserDetails.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<PortalUserPojo>(`${this.configuration.basePath}/users/${encodeURIComponent(String(id))}`,
            editUserDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validate(requestParameters: ValidateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<boolean>;
    public validate(requestParameters: ValidateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<boolean>>;
    public validate(requestParameters: ValidateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<boolean>>;
    public validate(requestParameters: ValidateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const field = requestParameters.field;
        if (field === null || field === undefined) {
            throw new Error('Required parameter field was null or undefined when calling validate.');
        }
        const value = requestParameters.value;
        if (value === null || value === undefined) {
            throw new Error('Required parameter value was null or undefined when calling validate.');
        }
        const accountType = requestParameters.accountType;
        if (accountType === null || accountType === undefined) {
            throw new Error('Required parameter accountType was null or undefined when calling validate.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (field) {
            field.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'field');
            })
        }
        if (value) {
            value.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'value');
            })
        }
        if (accountType !== undefined && accountType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>accountType, 'accountType');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<boolean>(`${this.configuration.basePath}/users/validate`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
