/**
 * Expira API
 * The Expira RESTful service using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: kiloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RegisterUserDto { 
    email: string;
    firstName: string;
    otpPin: string;
    lastName: string;
    password?: string;
    refCode?: string;
    phoneNumber?: string;
}

